// Generated by Framer (716dd6f)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Text, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["amE0LrokS", "xOL9wkQjs"];

const variantClassNames = {amE0LrokS: "framer-v-1d8ohix", xOL9wkQjs: "framer-v-1qj3ks8"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {Primary: "amE0LrokS", Secondary: "xOL9wkQjs"};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

const BASE62 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz"

function useRandomID(){const ref = React.useRef(null);
if (ref.current === null) {
ref.current = Array(5).fill(0).map(() => BASE62[Math.floor(Math.random() * BASE62.length)]).join("");
}
return ref.current;}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; title?: string }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style: externalStyle = {}, className, width, height, layoutId, variant: outerVariant = "amE0LrokS", title: tyWb40eQm = "Full time", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "amE0LrokS", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = useRandomID()

const { pointerEvents, ...style } = externalStyle

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div data-framer-generated initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-igY2K", classNames)} style={{display: "contents", pointerEvents: pointerEvents ?? undefined}}>
<motion.div {...restProps} className={cx("framer-1d8ohix", className)} data-framer-name={"Primary"} layoutDependency={layoutDependency} layoutId={"amE0LrokS"} ref={ref} style={{backgroundColor: "rgba(228, 0, 70, 0.2)", borderBottomLeftRadius: 100, borderBottomRightRadius: 100, borderTopLeftRadius: 100, borderTopRightRadius: 100, ...style}} transition={transition} variants={{xOL9wkQjs: {backgroundColor: "rgba(44, 37, 70, 0.2)"}}} {...addPropertyOverrides({xOL9wkQjs: {"data-framer-name": "Secondary"}}, baseVariant, gestureVariant)}><Text __fromCanvasComponent alignment={"center"} className={"framer-11gjc5i"} data-framer-name={"Full time"} fonts={["GF;Altone-500"]} layoutDependency={layoutDependency} layoutId={"mrsXiHWQh"} rawHTML={"<span style='font-size: 0; line-height: 0; tab-size: 4; white-space: inherit; word-wrap: inherit'><span style='font-size: 0'><span style=''>Full time</span><br></span></span>"} style={{"--framer-font-family": "\"Altone\", serif", "--framer-font-size": "16px", "--framer-font-style": "normal", "--framer-font-weight": 500, "--framer-letter-spacing": "0px", "--framer-line-height": "28px", "--framer-text-alignment": "center", "--framer-text-color": "rgb(228, 0, 70)", "--framer-text-decoration": "none", "--framer-text-transform": "none"}} text={tyWb40eQm} transition={transition} variants={{xOL9wkQjs: {"--framer-text-color": "rgb(32, 23, 71)"}}} verticalAlignment={"center"} withExternalLayout/></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-igY2K [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-igY2K * { box-sizing: border-box; }", ".framer-igY2K .framer-16awbki { display: block; }", ".framer-igY2K .framer-1d8ohix { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: visible; padding: 4px 16px 4px 16px; position: relative; width: min-content; }", ".framer-igY2K .framer-11gjc5i { flex: none; height: auto; overflow: visible; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-igY2K .framer-1d8ohix { gap: 0px; } .framer-igY2K .framer-1d8ohix > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-igY2K .framer-1d8ohix > :first-child { margin-left: 0px; } .framer-igY2K .framer-1d8ohix > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 44
 * @framerIntrinsicWidth 90
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"xOL9wkQjs":{"layout":["auto","auto"]}}}
 * @framerVariables {"tyWb40eQm":"title"}
 */
const FramerQ2Ecnox6Q: React.ComponentType<Props> = withCSS(Component, css) as typeof Component;
export default FramerQ2Ecnox6Q;

FramerQ2Ecnox6Q.displayName = "Tag 2";

FramerQ2Ecnox6Q.defaultProps = {height: 44, width: 90};

addPropertyControls(FramerQ2Ecnox6Q, {variant: {options: ["amE0LrokS", "xOL9wkQjs"], optionTitles: ["Primary", "Secondary"], title: "Variant", type: ControlType.Enum}, tyWb40eQm: {defaultValue: "Full time", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FramerQ2Ecnox6Q, [])